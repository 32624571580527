const appConfig = {
    apiPrefix: process.env.REACT_APP_BASE_URL,
    sendgridApiKey: process.env.REACT_APP_SENDGRID_API_KEY,
    sendgridBaseUrl: process.env.REACT_APP_SENDGRID_BASE_URL,
    authenticatedEntryPath: '/home',
    unAuthenticatedEntryPath: '/sign-in',
    tourPath: '/',
    locale: 'pt',
    enableMock: false,
};

export default appConfig;
