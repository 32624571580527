import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    isContract: false,
};

export const oportunitySlice = createSlice({
    name: 'oportunity',
    initialState,
    reducers: {
        setIsContract: (state, action) => {
            state.isContract = action.payload;
        },
    },
});

export const {setIsContract} = oportunitySlice.actions;

export default oportunitySlice.reducer;
