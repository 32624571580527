import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import appConfig from 'configs/app.config';
import {setSessionMessage} from '../auth/sessionSlice';

const baseQuery = fetchBaseQuery({
    baseUrl: appConfig.sendgridBaseUrl,
    prepareHeaders: (headers, {getState, endpoint}) => {
        headers.set('Authorization', `Bearer ${appConfig.sendgridApiKey}`);

        return headers;
    },
});

const baseQueryWithLogout = async (args, api, extraOptions) => {
    let result = await baseQuery(args, api, extraOptions);

    if (result?.data?.message) {
        api.dispatch(setSessionMessage({message: result?.data.message, type: 'success'}));
    }

    if (result?.error) {
        api.dispatch(setSessionMessage({message: result?.error.data.message, type: 'danger'}));
    }

    return result;
};

export const sendgridSlice = createApi({
    reducerPath: 'sendgridApi',
    baseQuery: baseQueryWithLogout,
    tagTypes: ['SgTemplates', 'SgTemplateVersion'],
    keepUnusedDataFor: 60,
    endpoints: builder => ({}),
});
