import {combineReducers} from 'redux';
import theme from './theme/themeSlice';
import auth from './auth';
import base from './base';
import locale from './locale/localeSlice';
import oportunity from './oportunity/oportunitySlice';
import {apiSlice} from './api/apiSlice';
import {sendgridSlice} from './sendgrid/sendgridSlice';

const rootReducer = asyncReducers => (state, action) => {
    const combinedReducer = combineReducers({
        theme,
        auth,
        base,
        locale,
        oportunity,
        [apiSlice.reducerPath]: apiSlice.reducer,
        [sendgridSlice.reducerPath]: sendgridSlice.reducer,
        ...asyncReducers,
    });
    return combinedReducer(state, action);
};

export default rootReducer;
